export const cols = [
  {
    label: '标题',
    minWidth: '200',
    slotName: 'title'
  },
  {
    // slotName: 'stateSlot',
    label: '更新时间',
    prop: 'updateTime',
    minWidth: '200'
  }
]
