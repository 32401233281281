<template>
  <div class="app-container articleManage">
    <!-- 默认分类 -->
    <div class="title">{{ $route.query.categoryName }}</div>

    <!-- 一排按钮 -->
    <div class="buttonArr">
      <el-button type="primary" size="small" @click="addArticle" v-p="['externaladmin:articleManage:createArticle']"
        >+ 添加文档</el-button
      >
      <el-button class="blueBoder" @click="moveCategory" size="small" v-p="['externaladmin:articleManage:moveCategory']"
        >移动分类</el-button
      >
      <el-button
        :loading="loading"
        class="blueBoder"
        size="small"
        @click="batchDelete"
        v-p="['externaladmin:articleManage:batchDelete']"
        >批量删除</el-button
      >
    </div>

    <!-- 表格 -->
    <CommonTable
      ref="table"
      :tableLoading="tableLoading"
      height="auto"
      :cols="cols"
      :infoData="data"
      @selection-change="handleSelectionChange"
    >
      <!-- 表格标题样式 -->
      <template #title="{ scoped: row }">
        <div class="templateName" @click="showArticle(row)">
          {{ row.title }}
        </div>
      </template>
      <!-- 操作列 -->
      <el-table-column label="操作" width="350px" align="center">
        <template slot-scope="{ row }">
          <span class="primary" @click="editArticle(row)" v-p="['externaladmin:articleManage:edit']">编辑</span>
          <el-divider direction="vertical"></el-divider>
          <Popover @sureHandler="onDelete(row)">
            <template #tip>
              <p>确定删除?</p>
            </template>
            <template #reference="{ scope: loading }">
              <color-text-btn
                class="update-btn"
                :loading="row.loading"
                size="mini"
                type="danger"
                v-p="['externaladmin:articleManage:delete']"
                >删除</color-text-btn
              >
            </template>
          </Popover>
          <!-- 拖拽图标 -->
          <el-divider direction="vertical"></el-divider>
          <svg-icon icon-class="menu" class="text-primary draggable-btn" v-p="['externaladmin:articleManage:Sort']" />
        </template>
      </el-table-column>
    </CommonTable>

    <!-- 分页器 -->
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />

    <!-- 移动分类对话框 -->
    <el-dialog
      title="移动到"
      v-if="visible"
      :visible="visible"
      width="499px"
      :before-close="handleClose"
      class="dialog-warpper"
    >
      <el-input
        style="width: 213px"
        clearable
        placeholder="输入分类名称搜索"
        prefix-icon="el-icon-search"
        size="mini"
        v-model="categoryName"
        @clear="searchClear"
        @keyup.enter.native="searchCate"
      />
      <div class="cateContent" v-loading="cateLoading">
        <template v-if="CategoryList.length > 0">
          <div class="cateContent_line border" v-for="v in CategoryList" :key="v.id" @click="changeCategory(v)">
            <div class="left">{{ v.categoryName }}</div>
            <template>
              <div v-if="v.id === $route.query.id" class="right">当前分类</div>
              <template v-else>
                <i v-if="changeCategoryId === v.id" class="iconfont icon-gougou right"></i>
              </template>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="noData">暂无数据</div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" size="small" @click="handleConfirmed">移动到此处</el-button>
      </span>
    </el-dialog>

    <!-- 查看文章对话框 -->
    <el-dialog
      title="查看详情"
      :visible="articleVisible"
      width="1000px"
      :before-close="articlehandleClose"
      class="articleWarpper"
    >
      <div class="articleContent" v-loading="articleLoading">
        <!-- 标题 -->
        <div class="articleContent_title" v-html="articleData.title"></div>
        <!-- 类型 -->
        <div class="articleContent_line2">时间:<span v-html="articleData.updateTime"></span></div>
        <!-- 内容 -->
        <div class="articleContent_content" v-html="articleData.content"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import { sortableMethodsMixin } from '@/mixins'
import { cols } from './module/cols'
import initDataMixin from '@/mixins/initData'
import {
  helpCenterContentDelete,
  helpCenterContentChangeCategory,
  helpCenterCategoryList,
  helpCenterContentDetail,
  helpCenterContentChangeSort
} from '@/api/helpcenter/helpcenter'
export default {
  mixins: [initDataMixin, sortableMethodsMixin],
  inject: ['reload'],
  data() {
    return {
      url: '/externaladmin/systemService/helpCenterContentList',
      query: {
        categoryId: this.$route.query.id,
        orderItems: [{ asc: true, column: 'sort_number' }]
      },
      // loading效果控制loading
      loading: false,
      // 表格标题数据cols
      cols,
      // 表格勾选项
      multiSelection: [],
      // 移动分类对话框显示控制visible
      visible: false,
      // 分类列表
      CategoryList: [],
      // 选择要移动到的分类
      changeCategoryId: '',
      // 分类名称
      categoryName: '',
      cateLoading: false,
      // 查看文章对话框显示控制articleVisible
      articleVisible: false,
      articleData: {},
      articleLoading: false
    }
  },
  async created() {
    try {
      const { code, detail } = await helpCenterCategoryList({ page: { pageSize: 0 } })
      if ($SUC({ code })) {
        this.CategoryList = detail
      } else {
        this.$message.error('获取分类数据失败')
      }
    } catch (e) {
      this.$message.error('获取分类数据失败')
    }
  },
  updated() {
    let {
      $refs: { table }
    } = this
    table = table.table
    if (table) {
      let tbody = table.$refs.bodyWrapper.getElementsByTagName('tbody')[0]
      let trs = tbody.getElementsByTagName('tr')
      trs.forEach((tr, index) => tr.setAttribute('data-id', this.data[index].sortNumber))
      this.sortable = Sortable.create(tbody, {
        handle: '.draggable-btn',
        onEnd: async (evt) => {
          try {
            this.tableLoading = true
            const { oldIndex, newIndex } = evt
            // 下移的情况
            //移动的项 oldIndex<=newIndex
            if (oldIndex <= newIndex) {
              this.data[oldIndex].sortNumber = this.data[newIndex].sortNumber
              for (let i = 0; i < this.data.length; i++) {
                if (i <= newIndex && i !== oldIndex) {
                  // 其他项序号自减1
                  this.data[i].sortNumber = --this.data[i].sortNumber
                }
              }
            } else {
              // 上移的情况
              let removeId = this.data[newIndex].id
              let sortNumber = this.data.filter((item) => item.id === removeId)[0].sortNumber
              // 序号替换
              console.log('上移的情况====', oldIndex, newIndex)
              this.data[oldIndex].sortNumber = sortNumber
              for (let i = 0; i < this.data.length; i++) {
                if (i < oldIndex && i >= newIndex) {
                  this.data[i].sortNumber = ++sortNumber
                }
              }
            }

            let list = this.data.map(({ id, sortNumber }) => ({
              id,
              sortNumber
            }))

            console.log('result==', list)
            await helpCenterContentChangeSort({ list })
          } catch (e) {
            console.log('err===', e)
            this.$message.error('移动分类失败')
          } finally {
            this.init()
          }
        }
      })
    }
  },
  methods: {
    // 表格勾选内容改变触发
    handleSelectionChange(val) {
      this.multiSelection = val
      // console.log('val',val)
    },
    // 表格删除方法onDelete
    async onDelete(row) {
      const idList = []
      idList.push(row.id)
      try {
        this.$set(row, 'loading', true)
        const { code } = await helpCenterContentDelete({ idList })
        if ($SUC({ code })) {
          this.$message.success('删除文章成功')
          this.init()
        } else {
          this.$message.error('删除文章失败')
        }
      } catch (e) {
        this.$message.error('删除文章失败')
      } finally {
        this.row.loading = false
      }
    },
    // 批量删除
    async batchDelete() {
      if (this.multiSelection.length < 1) return this.$message.warning('请先选择数据')
      const idList = []
      this.multiSelection.map((item) => {
        idList.push(item.id)
      })
      try {
        this.loading = true
        const { code } = await helpCenterContentDelete({ idList })
        if ($SUC({ code })) {
          this.$message.success('批量删除文章成功')
          this.init()
        } else {
          this.$message.error('批量删除文章失败')
        }
      } catch (e) {
        this.$message.error('批量删除文章失败')
      } finally {
        this.loading = false
      }
    },
    // 移动分类对话框moveCategory
    moveCategory() {
      if (this.multiSelection.length < 1) return this.$message.warning('请先选择数据')
      this.visible = true
    },
    // 分类筛选
    async searchCate() {
      this.cateLoading = true
      const { detail } = await helpCenterCategoryList({ categoryName: this.categoryName })
      this.CategoryList = detail || []
      this.cateLoading = false
    },
    // 清除分类筛选
    async searchClear() {
      this.cateLoading = true
      const { detail } = await helpCenterCategoryList({})
      this.CategoryList = detail || []
      this.cateLoading = false
    },
    // 移动分类点击
    changeCategory(v) {
      // console.log(v)
      this.changeCategoryId = v.id
    },
    // 移动分类确认对话框handleConfirmed
    async handleConfirmed() {
      const contentIdList = []
      this.multiSelection.map((item) => {
        contentIdList.push(item.id)
      })
      try {
        this.loading = true
        const { code } = await helpCenterContentChangeCategory({
          categoryId: this.changeCategoryId,
          contentIdList
        })
        if ($SUC({ code })) {
          this.$message.success('移动分类成功')
          this.handleClose()
          this.init()
        } else {
          this.$message.error('移动分类失败')
        }
      } catch (e) {
        this.$message.error('移动分类失败')
      } finally {
        this.loading = false
      }
    },
    // 移动分类对话框关闭handleClose
    handleClose() {
      this.visible = false
      this.changeCategoryId = ''
    },
    // 查看文章对话框开启方法showArticle
    async showArticle(row) {
      this.articleVisible = true
      try {
        this.articleLoading = true
        const { code, detail } = await helpCenterContentDetail({ id: row.id })
        if ($SUC({ code })) {
          this.articleData = detail
        } else {
          this.$message.error('获取数据失败')
        }
      } catch (e) {
        this.$message.error('获取数据失败')
      } finally {
        this.articleLoading = false
      }
    },
    // 查看文章对话框关闭方法articlehandleClose
    articlehandleClose() {
      this.articleVisible = false
    },
    // 添加文章addArticle
    addArticle() {
      this.$router.push({
        path: '/infoManage/createArticle',
        query: {
          categoryId: this.query.categoryId,
          categoryName: this.$route.query.categoryName
        }
      })
    },
    // 编辑文章editArticle
    editArticle(row) {
      this.$router.push({
        path: '/infoManage/createArticle',
        query: {
          categoryId: this.query.categoryId,
          categoryName: this.$route.query.categoryName,
          id: row.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.articleManage {
  .color-btn-component {
    font-size: 14px;
  }
  // 默认分类
  .title {
    margin: 12px 0 29px 6px;
    font-size: 20px;
    color: #1a1a1a;
  }
  // 一排按钮
  .buttonArr {
    margin-bottom: 28px;
    .blueBoder {
      border-color: #3841db;
      color: #3841db;
    }
  }
  // 表格标题样式
  .templateName {
    color: #3841db;
    cursor: pointer;
  }
  // 表格内编辑.primary
  .primary {
    color: #3841db !important;
    cursor: pointer;
  }
  // 移动分类
  .dialog-warpper {
    // 分类内容
    .cateContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 13px;
      width: 459px;
      height: 289px;
      border: 1px solid #dcdee0;
      overflow-y: auto;
      .noData {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .border {
        border-bottom: 1px solid #dcdee0;
      }
      .cateContent_line {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        justify-content: space-between;
        padding: 0 22px;
        height: 42px;
        width: 439px;
        .right {
          width: 57px;
          text-align: right;
        }
        .icon-gougou {
          color: #3841db;
        }
      }
      .cateContent_line:last-child {
        border: 0;
      }
      .cateContent_line:first-child {
        border-bottom: 1px solid #dcdee0 !important;
      }
    }
    ::v-deep {
      .el-dialog__title {
        font-size: 16px;
        color: #495060;
      }
      .el-dialog__header {
        height: 52px;
        line-height: 52px;
        padding: 0 0 0 24px;
        border: 1px solid #dcdee0;
      }
      // .el-input__inner {
      //   width: 213px;
      //   // height: 36px;
      // }
    }
  }
  // 查看文章对话框
  .articleWarpper {
    ::v-deep {
      .el-dialog__title {
        font-size: 16px;
        color: #495060;
      }
      .el-dialog__header {
        height: 52px;
        line-height: 52px;
        padding: 0 0 0 24px;
        border: 1px solid #dcdee0;
      }
    }
    .articleContent {
      height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      width: 100%;
      padding: 5px 0 0 4px;
      // 标题
      .articleContent_title {
        margin: 0 0 20px 0;
        color: #595961;
        font-size: 20px;
        text-align: left;
        width: 100%;
      }
      // 类型
      .articleContent_line2 {
        color: #a1a1a1;
        font-size: 14px;
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
      }
      // 内容
      .articleContent_content {
        width: 100%;
        ::v-deep {
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
